<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col lg="8" md="6" class="d-none d-lg-flex p-0">
        <SplashScreen />
      </b-col>
      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1"> {{ $t('common.contact') }} MyFlightConnect </b-card-title>

          <!-- form -->
          <validation-observer ref="contactValidation" #default="{ handleSubmit }">
            <b-form class="auth-login-form mt-2" @submit.prevent="handleSubmit(onSubmit)">
              <!-- name -->
              <b-form-group :label="$t('user.name')" label-for="contact-name">
                <validation-provider #default="{ errors }" :name="$t('user.name')" rules="required" class="validation-required">
                  <b-form-input id="contact-name" v-model="contact.name" :state="errors.length > 0 ? false : null" name="contact-name" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- name -->

              <!-- email -->
              <b-form-group :label="$t('login.email')" label-for="contact-email">
                <validation-provider #default="{ errors }" :name="$t('login.email')" rules="required|email" class="validation-required">
                  <b-form-input id="contact-email" v-model="contact.email" :state="errors.length > 0 ? false : null" name="contact-email" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- email -->

              <!-- phone number -->
              <b-form-group :label="$t('user.phone')" label-for="emergencyTel" rules="required">
                <validation-provider #default="{ errors }" :name="$t('user.phone')" rules="required" class="validation-required">
                  <vue-tel-input
                    v-model="contact.phoneNumber"
                    :class="phoneInput && !phoneInput.valid && contact.phoneNumber ? 'is-invalid' : null"
                    class="form-control"
                    :default-country="userLang"
                    @validate="val => (phoneInput = val)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small v-if="phoneInput && !phoneInput.valid && contact.phoneNumber" class="text-danger">{{ $t('validator.invalid_tel.message') }}</small>
                </validation-provider>
              </b-form-group>
              <!-- phone number -->

              <!-- company name -->
              <b-form-group :label="$t('user.company_name')" label-for="company-name">
                <validation-provider #default="{ errors }" name="company-name" rules="required" class="validation-required">
                  <b-form-input id="company-name" v-model="contact.companyName" :state="errors.length > 0 ? false : null" name="company-name" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- company name -->

              <!-- company website -->
              <b-form-group :label="$t('user.company_website')" label-for="company-website">
                <b-form-input id="company-website" v-model="contact.webSiteUrl" name="company-website" />
              </b-form-group>
              <!-- company website -->

              <!-- company website -->
              <b-form-group :label="$t('common.message')" label-for="message">
                <b-form-textarea v-model="contact.message" rows="5" />
              </b-form-group>

              <!-- submit buttons -->
              <b-button class="mt-1" type="submit" block @click.prevent="validationForm">
                <font-awesome-icon icon="paper-plane" />
                {{ $t('action.send') }}
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <div>
              <span>{{ $t('login.already_have_account') }}</span>
              <b-link :to="{ name: 'login' }">
                <span> {{ $t('login.sign_in_instead') }}</span>
              </b-link>
            </div>
            <div>
              <span>{{ $t('login.new_on_platform') }}</span>
              <b-link :to="{ name: 'register' }">
                <span> {{ $t('login.create_an_account') }}</span>
              </b-link>
            </div>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapActions, mapState } from 'vuex'
import { email, required } from '@core/utils/validations/validations'
import { postContactRequest } from '@/request/globalApi/requests/contactRequests'
import SplashScreen from '@/views/base/SplashScreen.vue'

export default {
  name: 'Contact',
  components: {
    VueTelInput,
    SplashScreen,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      phoneInput: null,
      contact: {
        name: '',
        email: '',
        phoneNumber: '',
        companyName: '',
        webSiteUrl: null,
        message: null,
        recaptchaToken: null,
      },
      required,
      email,
    }
  },
  computed: {
    ...mapState('appConfig', ['layout']),
    userLang() {
      return this.$store.state.appConfig.lang
    },
  },
  methods: {
    ...mapActions('auth', ['login']),
    async onSubmit() {
      if (process.env.VUE_APP_RECAPTCHA_SITE_KEY) {
        await this.$recaptchaLoaded()
        this.contact.recaptchaToken = await this.$recaptcha('contact')
      }

      postContactRequest(this.contact).then(() => true)
    },
    validationForm() {
      this.$refs.contactValidation.validate().then(success => {
        if (!this.phoneInput.valid) {
          this.toaster(this.$t('account.main_informations.phone.invalid_format'), 'danger', 'AlertTriangleIcon')
          return false
        }
        if (success) {
          this.onSubmit()
          return true
        }
        return false
      })
    },
  },
}
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
